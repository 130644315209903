
import { defineComponent, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import KTAside from '@/layout/aside/training_institute/Aside.vue';
import KTHeader from '@/layout/header/Header.vue';
import KTFooter from '@/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/LayoutService';
import KTScrollTop from '@/layout/extras/ScrollTop.vue';
import KTUserMenu from '@/layout/header/partials/ActivityDrawer.vue';
import KTTrainningInstitute from '@/layout/header/partials/trainning_institute/trainning_institute_drawer.vue';
import KTCourseDrawer from '@/layout/header/partials/course/CourseInfoDrawer.vue';
import KTCourseMapDrawer from '@/layout/header/partials/trainning_institute/MapCourse_Drawer.vue';
import KTInstituteTargerDrawer from '@/layout/header/partials/trainning_institute/InstituteTargetDrawer.vue';
import KTTargetChangeSummaryDrawer from '@/layout/header/partials/trainning_institute/TargetChangeSummeryDrawer.vue';
import KTTrainningInstituteEmployee from '@/layout/header/partials/employeeList/EmployeeListDrawer.vue';
import KTMapEmployeeInstituteDrawer from '@/layout/header/partials/employeeList/MapEmployeeInstituteDrawer.vue';
import KTAddRoom from '@/layout/header/partials/trainning_institute/add_room_Drawer.vue';
import KTChangeTableHeader from '@/layout/header/partials/trainning_institute/TableHeaderChange.vue';
import KTTrainningPartner from '@/layout/header/partials/trainning_institute/add_trainningPartner_drawer.vue';
import KTEditRoom from '@/layout/header/partials/trainning_institute/edit_room_drawer.vue';
import KTLoader from '@/components/Loader.vue';
import KTCreateApp from '@/components/modals/wizards/CreateAppModal.vue';
import KTDrawerMessenger from '@/layout/extras/DrawerMessenger.vue';
import { Actions } from '@/store/enums/StoreEnums';
import {
  MenuComponent,
  DrawerComponent,
  ScrollComponent,
} from '@/assets/ts/components/index';
import { removeModalBackdrop } from '@/core/helpers/dom';
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from '@/core/helpers/config';

export default defineComponent({
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTCreateApp,
    KTUserMenu,
    KTDrawerMessenger,
    KTLoader,
    KTTrainningInstitute,
    KTTrainningInstituteEmployee,
    KTMapEmployeeInstituteDrawer,
    KTAddRoom,
    KTTrainningPartner,
    KTEditRoom,
    KTCourseDrawer,
    KTCourseMapDrawer,
    KTTargetChangeSummaryDrawer,
    KTInstituteTargerDrawer,
    KTChangeTableHeader,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });

    onMounted(() => {
      //check if current user is authenticated
      if (!store.getters.isUserAuthenticated) {
        router.push({ name: 'sign-in' });
      }

      DrawerComponent.bootstrap();
      ScrollComponent.bootstrap();
      DrawerComponent.updateAll();
      ScrollComponent.updateAll();

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
      }, 500);
    });

    watch(
        () => route.path,
        () => {
          MenuComponent.hideDropdowns(undefined);

          DrawerComponent.hideAll();

          // check if current user is authenticated
          if (!store.getters.isUserAuthenticated) {
            router.push({ name: 'sign-in' });
          }

          removeModalBackdrop();
        }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
