const Menu = [
  {
    heading: "menu",
    route: "/employee",
    pages: [
      {
        sectionTitle: "Employee",
        route: "/association/employee",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Add Employee",
            route: "/employee/new_employee",
          },
          {
            heading: "Employee List",
            route: "/employee/employee_list",
          },
        ],
      },

      // {
      //   heading: 'Add Training Institute Target',
      //   route: '/employee/institute-target',
      //   fontIcon: 'bi-dashboard',
      //   svgIcon: '/media/icons/duotune/general/gen026.svg',
      // },
    ],
  },
];

export default Menu;
