
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  name: 'kt-drawer-training-institute-edit',
  mixins: [apiEndpoint],
  components: { Field },
  props: {
    data: { type: Object },
  },

  data() {
    return {
      api_url: '',
      load: false,
      dialogImageUrl: '',
      imageUrl: '',
      imageEntityLogoUrl: '',
      dialogVisible: false,
      alertText: false,
      formData: {
        entity_id: '',
        training_institute_type: '',
        training_institute_type_value: '',
        short_name: '',
        institute_name: '',
        institute_description: '',
        registration_number: '',
        registration_date: '',
        registration_authority: '',
        address: '',
        postcode: '',
        sub_district: '',
        district_value: '',
        division_value: '',
        sub_district_value: '',
        district: '',
        division: '',
        telephone: '',
        fax: '',
        email: '',
        web_url: '',
        image_file_name: '',
        image_dir: '',
        number_of_training_room: '',
        training_capacity: '',
        total_trainer_male: '',
        total_trainer_female: '',
        lab_equipment: '',
        id: '',
        logo: '',
      } as any,
      url: '',
      drawer: {},

      entityInfos: [],
      instituteTypes: [],
      divisions: [],
      district: [],
      upazila: [],
      avatarFiles: [],
      fileList: [],
      loading: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTrainningInstituteTypes();
    await this.getDivision();
    await this.getDistrict();
    await this.getUpazila();
    this.emitter.on('institute-edit-modal-data', async (data) => {
      this.load = false;
    });
  },
  methods: {
    updatePhoto(e) {
      this.formData.logo = e.target.files[0];
      this.url = URL.createObjectURL(this.formData.logo);
    },
    async targetLog() {
      this.load = true;
      await ApiService.get('institute/edit/' + this.formData.id)
        .then((response) => {
          this.load = false;
          if (response.data.data) {
            this.formData = response.data.data;
          }

          console.log(this.formData);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();

      formData.set('institute_logo', this.formData.logo);

      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      formData.set('district', this.formData.district_value);
      formData.set('division', this.formData.division_value);
      formData.set('sub_district', this.formData.sub_district_value);
      formData.set(
        'training_institute_type',
        this.formData.training_institute_type_value
      );
      let user_id = VueCookieNext.getCookie('_seip_user');
      let role_id = VueCookieNext.getCookie('_cpv_role_id');
      formData.set('user_id', user_id.id);
      formData.set('role_id', role_id);
      this.loading = true;
      await ApiService.post('institute/update', formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              // this.formData = {
              //   id: "",
              //   entity_info_id: "",
              //   tranche_id: "",
              //   target_year_id: "",
              //   target_year: "",
              //   target_trainee_number: "",
              // };
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
    async getTrainningInstituteTypes() {
      await ApiService.get('configurations/institute_type/list')
        .then((response) => {
          this.instituteTypes = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getDivision() {
      await ApiService.get('configurations/geo_division/list')
        .then((response) => {
          this.divisions = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getDistrict() {
      await ApiService.get(
        'configurations/geo_district/list?division=' +
          this.formData.division_value
      )
        .then((response) => {
          this.district = [];
          this.district = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getUpazila() {
      await ApiService.get(
        'configurations/geo_upazila/list?division=' +
          this.formData.division_value +
          '&district=' +
          this.formData.district_value
      )
        .then((response) => {
          this.upazila = [];
          this.upazila = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
  },
});
